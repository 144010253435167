<template>
  <div class="c_pingbi_time_pop">
    <el-dialog :title="seeStatus == 1 ? '新增公告': '编辑公告'" width="900px" top="30px" @close="onClose" :close-on-click-modal="false" :visible.sync="show">
        <el-form size="small" label-width="100px">
          <el-row>
            <el-col :span="10" v-if="form.id">
              <el-form-item label="公告ID:">
                  <el-input disabled v-model="form.id" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="公告标题:">
                  <el-input v-model="form.title"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="发布时间:">
                    <el-date-picker :default-value="new Date()"  v-model="form.createTime" type="datetime" placeholder="选择发布时间"> </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="状态:">
                  <el-select v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in options"  :key="item.value" :label="item.title" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <tinymce-rich id="busbulletin-rich" v-model="form.content" @handleImgUpload="apiUploadFileHandel"/>
            </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="apiSaveBulletinHandel">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiUploadFile, apiSaveBulletin } from "@/utils/api.js";
import TinymceRich from '@/components/TinymceRich.vue'
export default {
  components: { TinymceRich },
  data() {
    return {
      options: [
        { title: '禁用', value: 0 },
        { title: '正常', value: 1 }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 86400000);
        }
      },
      seeStatus:1,
      show: false,
      form: {
        id: '',
        title: '',
        content: '',
        status: 1,
        createTime: new Date().getTime(),
        create_time: 0
      },
    }
  },
  methods: {

    // 新增修改公告
    async apiSaveBulletinHandel() {
      if(!this.form.title) return this.$message.error('请填写公告标题')
      if(!this.form.content) return this.$message.error('请填写公告内容')
      if(typeof this.form.createTime == 'number') this.form.create_time = parseInt(this.form.createTime / 1000) 
      if(typeof this.form.createTime == 'object') this.form.create_time = parseInt(this.form.createTime.getTime() / 1000)
      let loading = this.$loading()
      const { success, data } = await apiSaveBulletin(this.form).catch(() => { loading.close() })
      loading.close()
      if(!success) return
      this.onClose()
      this.$emit('emitSaveSuccess')
      console.log(data)
    },

     // 上传
    async apiUploadFileHandel(blobInfo, resolve, failure) {
      let file = blobInfo.blob()
      console.log(file)
      let formData = new FormData()
      formData.append('file', file)
      const loading = this.$loading()
      const { success, data } = await apiUploadFile(formData).catch(() => {
        loading.close()
        failure()
      })
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      resolve(data.url)
    },

    // status //1 新增 2编辑
    onShow(data = { id: '' }, status) {
      this.seeStatus = status
      if(status == 2) {
        this.form = Object.assign({}, this.form, data)
        this.form.createTime = data.create_time * 1000
      }
      this.show = true
    },

    onClose() {
      this.show = false
      this.form = {
        id: '',
        title: '',
        content: '',
        status: 1,
        createTime: new Date().getTime(),
        create_time: 0
      }
    },
  }
}
</script>

<style lang='scss'>

</style>